import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar2'
import PageTitle from '../../components/pagetitle'
import Contactpage from '../../components/Contactpage'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Chat from '../../components/chat'
import Image from "../../images/slider5.jpg"


const ContactPage =() => {
    return(
        <Fragment>
            <Navbar/>
            <PageTitle pageTitle={'Contact Us'} img={Image} pagesub={'Contact'}/> 
            <Contactpage/>
            <Footer footerClass={'wpo-ne-footer-2'}/>
            <Chat/>
        </Fragment>
    )
};
export default ContactPage;

