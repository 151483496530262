import React, { Component } from 'react'
import { Collapse, CardBody, Card } from 'reactstrap';
import { Link } from 'react-router-dom'
import './style.css';

const menus = [
    {
        id: 1,
        title: 'Home',
        link: '/home',
       
    },



    {
        id: 2,
        title: 'Apartments',
        link: '/room',
        submenu: [
            {
                id: 21,
                title: 'Studio Apartments',
                link: '/room-single_studio'
            },
            {
                id: 22,
                title: 'Deluxe Studio Apartments',
                link: '/room-single_deluxe'
            },
            {
                id: 42,
                title: 'Superior Deluxe Studio Apartments',
                link: '/room-single_superior'
            },
            {
                id: 43,
                title: 'Executive Apartment',
                link: '/room-single_executive'
            }
        ]
    },
    {
        id: 9,
        title: 'My Booking',
        link: '/myreservation',
    },
    {
        id: 90,
        title: 'Gallery',
        link: '/gallery',
    },
 {
        id: 190,
        title: 'About',
        link: '/about',
    },

    {
        id: 390,
        title: 'Service',
        link: '/service',
       
    },

   
    {
        id: 88,
        title: 'Contact',
        link: '/contact',
    }


]


export default class MobileMenu extends Component {

    state = {
        isMenuShow: false,
        isOpen: 0,
    }

    menuHandler = () => {
        this.setState({
            isMenuShow: !this.state.isMenuShow
        })
    }

    setIsOpen = id => () => {
        this.setState({
            isOpen: id === this.state.isOpen ? 0 : id
        })
    }

    render() {

        const { isMenuShow, isOpen } = this.state;

        const ClickHandler = () => {
            window.scrollTo(10, 0);
        }

        return (
            <div>
                <div className={`mobileMenu ${isMenuShow ? 'show' : ''}`}>
                    {/* <div className="clox" onClick={this.menuHandler}>Close Me</div> */}

                    <ul className="responsivemenu">
                        {menus.map(item => {
                            return (
                                <li key={item.id}>
                                    {item.submenu ? <p onClick={this.setIsOpen(item.id)}>
                                        {item.title}
                                        {item.submenu ? <i className="fa fa-angle-right" aria-hidden="true"></i> : ''}
                                    </p> : <Link to={item.link}>{item.title}</Link>}
                                    {item.submenu ?
                                        <Collapse isOpen={item.id === isOpen}>
                                            <Card>
                                                <CardBody>
                                                    <ul>
                                                        {item.submenu.map(submenu => (
                                                            <li key={submenu.id}><Link onClick={ClickHandler} className="active" to={submenu.link}>{submenu.title}</Link></li>
                                                        ))}
                                                    </ul>
                                                </CardBody>
                                            </Card>
                                        </Collapse>
                                        : ''}
                                </li>
                            )
                        })}
                    </ul>

                </div>

                <div className="showmenu" style={{backgroundColor:"rgba(0,0,0,0)"}} onClick={this.menuHandler}><i  style={{color:"#f7c33f"}} className="fa fa-bars" aria-hidden="true"></i></div>
            </div>
        )
    }
}
