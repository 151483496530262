
import React from "react";
import {Link} from 'react-router-dom'
import Pricing from '../Rcomponents/descontent/pricing'
import Review from '../Rcomponents/descontent/review'
import AddReview from '../Rcomponents/descontent/addReview'
import OtherRoom from '../Rcomponents/descontent/otherRoom'
import rmd1 from '../../../images/room/executive/image1.jpg'
import rmd2 from '../../../images/room/executive/image2.jpg'

const Description = (props) => {

    return(
        <div className="Room-details-area">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                        {/* <div className="room-description">
                            <div className="room-title">
                                <h2>Description</h2>
                            </div>
                            <p className="p-wrap">It's difficult to find examples of lorem ipsum in use before Letraset made it popular as a dummy text in the 1960s, although McClintock says he remembers coming across the lorem ipsum passage in a book of old metal type samples.</p>
                            <p>So when is it okay to use lorem ipsum? First, lorem ipsum works well for staging. It's like the props in a furniture store—filler text makes it look like someone is home. The same Wordpress template might eventually be home to a fitness blog, a photography website, or the online journal of a cupcake fanatic. Lorem ipsum helps them imagine what the lived-in website might look like.</p>
                            <p>Second, use lorem ipsum if you think the placeholder text will be too distracting. For specific projects, collaboration between copywriters and designers may be best, however, like Karen McGrane said, draft copy has a way of turning any meeting about layout decisions into a discussion about word choice. So don't be afraid to use lorem ipsum to keep everyone focused.</p>
                        </div> */}
                        <div className="room-details-service">
                            <div className="row">
                                <div className="room-details-item">
                                    <div className="row">
                                        <div className="col-md-5 col-sm-12 col-12">
                                            <div className="room-d-text"  style={{borderRadius:10, marginBottom:20}}>
                                                <div className="room-title">
                                                    <h2>Room Amenities</h2>
                                                </div>
                                                <ul>

                                                    <li><Link to="/room-single">Full Size Refrigerator</Link></li>
                                                    <li><Link to="/room-single">Fully Furnished Kitchen</Link></li>
                                                    <li><Link to="/room-single">Dining Area</Link></li>

                                                    <li><Link to="/room-single">2 Air Conditioner</Link></li>
                                                    <li><Link to="/room-single">Room Side Balcony</Link></li>
                                                    <li><Link to="/room-single">Fully Furnished Washroom</Link></li>
                                                    <li><Link to="/room-single">In-room Safe For Valuables</Link></li>
                                                    <li><Link to="/room-single">2 HD Flat Screen TV</Link></li>
                                                    <li><Link to="/room-single">King Size Bed</Link></li>
                                                    <li><Link to="/room-single">Air Bed Available at a fee</Link></li>

                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-md-7 col-sm-12 col-12">
                                            <div className="room-d-img">
                                                <img src={rmd1} alt=""/>
                                            </div>
                                        </div>
                                        <div className="col-md-7 col-sm-12 col-12">
                                            <div className="room-d-img">
                                                <img src={rmd2} alt=""/>
                                            </div>
                                        </div>
                                        <div className="col-md-5 col-sm-12 col-12">
                                            <div className="room-d-text2"  style={{borderRadius:10, marginBottom:20}}>
                                                <div className="room-title">
                                                    <h2>On-site Services</h2>
                                                </div>
                                                <ul>
                                                    <li><Link to="#">Scheduled Room Cleaning </Link></li>
                                                    <li><Link to="#">Outdoor Swimming Pool</Link></li>
                                                    <li><Link to="#">Free Parking</Link></li>
                                                    <li><Link to="#">Picnic Area</Link></li>
                                                    <li><Link to="#">Designated Smoking Area</Link></li>
                                                    <li><Link to="#">Car Rental Hire (at a fee)</Link></li>
                                                    <li><Link to="#">Free Wifi</Link></li>
                                                    <li><Link to="#">24-hour CCTV & Security</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <Pricing/> */}
                        {/* <Review/> */}
                        {/* <AddReview/> */}
                        <OtherRoom/>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Description;