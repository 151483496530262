import React from 'react'
import {Link} from 'react-router-dom'
import fimg1 from '../../images/featured/1.jpg'
import fimg2 from '../../images/featured/2.jpg'
import fimg3 from '../../images/featured/3.jpg'
import fimg4 from '../../images/featured/4.jpg'
import fimg5 from '../../images/featured/5.jpg'
import fimg6 from '../../images/featured/6.jpg'

import './style.css'

const Features = (props) => {

    const features = [
        {
          Fimg:fimg1,
          heading:"Food",
          link:"/room-single",
        },
        {
          Fimg:fimg2,
          heading:"Pool",
          link:"/room-single",
        },
        {
          Fimg:fimg3,
          heading:"Gym",
          link:"/room-single",
        },
        {
          Fimg:fimg4,
          heading:"Events",
          link:"/room-single",
        },
        {
          Fimg:fimg5,
          heading:"Transport",
          link:"/room-single",
        },
        {
          Fimg:fimg6,
          heading:"Relax",
          link:"/room-single",
        },
    ]

    const ClickHandler = () =>{
      window.scrollTo(10, 0);
   }
    return(
        <div className="container">
          <div className="col-12">
                    
            <div className="container-fluid">
                <div className="row grid ">
                <div className="col-lg-6 items col-md-6 col-sm-12 col-12" >
                <div className="wpo-section-title">
                            {/* <span><b>Our Rooms</b></span> */}
                            <h3 style={{textAlign:"center", color:"#ca9f40" }}>Our Vision</h3>
                        </div>

                        <p>Our vision's concept is to continue to implement and set the highest service and quality standards in order to justify and uphold our reputation among guests, partners, competitors, and the general public.</p>
                    </div>
                    
                     <div className="col-lg-6 items col-md-6 col-sm-12 col-12" >
                <div className="wpo-section-title">
                            {/* <span><b>Our Rooms</b></span> */}
                            <h3 style={{textAlign:"center", color:"#ca9f40" }}>Our Mision</h3>
                        </div>

                        <p>The mission of our apartments is to provide outstanding lodging facilities and services to our guests. Our apartments focuses on individuals, business and leisure.</p>
                    </div>


                        </div>
                </div>
            </div>
        </div>
    )
}

export default Features;