import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar2'
import PageTitle from '../../components/pagetitle'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import RoomSection from '../../components/RoomSection'
import Chat from '../../components/chat'
import Image from "../../images/slider/slide-3.jpg"



const RoomPage =() => {
    return(
        <Fragment>
            <Navbar/>
            <PageTitle pageTitle={'Explore Our Rooms'} img={Image} pagesub={'Enjoy Your Stay'}/> 
            <RoomSection/>
            <Footer/>
            <Chat/>
        </Fragment>
    )
};
export default RoomPage;
