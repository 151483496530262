import React from 'react'
import {Link} from 'react-router-dom'
import ab1 from '../../images/about/2.jpg'

const About2 = (props) => {
    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }
    return(
        <div className="wpo-about-area-2 section-padding" >
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="wpo-about-img">
                            <img src={ab1} alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 colsm-12">
                        <div className="wpo-about-text">
                            <div className="wpo-section-title">
                            <span><b style={{fontSize:"34px", color:"#ca9f40"}}>Fun For The Whole Family!</b></span>
                            </div>
                            <p style={{textAlign:"justify"}}>
                                
                            Nestled in the leafy Green Hills, West Legon, our high-quality serviced apartments are only a 15-minute drive from Kotoka International Airport and a 20-minute drive to Accra Central. We offer fully-furnished luxurious serviced apartments, with high-quality facilities and services.

You can choose from our various short or long stay options. Guests are also welcome to explore the grounds of Cromer Apartments and enjoy all the amenities available from taking a dip and cooling off in the large pool, to taking a stroll in the beautiful gardens. Our friendly and experienced staff are always on hand to provide assistance and ensure you enjoy your stay with us during your time in Accra
                                
                                 </p>
                            <div className="btns">
                                <Link onClick={ClickHandler} to="/about" className="theme-btn-s2"  style={{backgroundColor:"black", color:"#ca9f40"}}>More About Us</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About2;