import React, { Component, useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ts1 from '../../images/testimonial/img-1.jpg'
import ts2 from '../../images/testimonial/img-2.jpg'
import ts3 from '../../images/testimonial/img-3.jpg'
import './style.css'
import { Link } from 'react-router-dom'
import axios from "axios"

const Testimonial =()=> {
    
const[review, setreview]= useState([])
const[loading, setloading]= useState(true)

    
    const getrooms = async () => {
        await axios.post("https://cromer.sisrevolution.com/api/review/getreview/",
            {

            }).then(res => {
                if (res.status === 200) {
                    setreview(res.data)
                    console.log(res)
                }
            })
    }

    useEffect(()=>{

        getrooms()

    },[loading])

        var settings = {
            dots: false,
            arrows: false,
            speed: 3000,
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2500,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        infinite: true,
                    }
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const testimonial = [
            {
                tsImg: ts1,
                Des: "“The apartment staff were very helpful. It was really nice having the pool just at the backyard of my apartment and being able to take a walk in the garden”",
                Title:'Walton',
                Sub: "Germany",
            },
            {
                tsImg: ts2,
                Des: "“The room was very clean, tidy and modern. The pool was great. The host and the staff of the facility are very nice. There is a small snack shop, which offers good snacks”",
                Title:'Mary',
                Sub: "Canada",
            },
            {
                tsImg: ts3,
                Des: "“Nice, quiet location out of the hustle and bustle of Accra.”",
                Title:'Lily monalisa',
                Sub: "USA",
            },
            {
                tsImg: ts1,
                Des: "“I love the Experience”",
                Title:'Diana',
                Sub: "Accra",
            }
        ]
        return (
            <div className="testimonial-area section-padding">
                <div className="container">
                    <div className="col-12">
                        <div className="wpo-section-title">
                            {/* <span>What Say Clients</span> */}
                            <h2 style={{color:"#ca9f40"}}>Our Clients are <br /> Important to Us</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="testimonial-slider clearfix">
                                <Slider {...settings}>
                                    {testimonial.map((tesmnl, tsm) => (
                                        <div className="grid" key={tsm}>
                                            <div className="ratting">
                                                <ul>
                                                    <li><i className="fi flaticon-star"></i></li>
                                                    <li><i className="fi flaticon-star"></i></li>
                                                    <li><i className="fi flaticon-star"></i></li>
                                                    <li><i className="fi flaticon-star"></i></li>
                                                    <li><i className="fi flaticon-star"></i></li>
                                                </ul>
                                            </div>
                                            <div className="quote">
                                                <p>{tesmnl.Des}</p>
                                            </div>
                                            <div className="client-info">
                                                <div className="client-img">
                                                    <img src={tesmnl.tsImg} alt="" />
                                                </div>
                                                <div className="client-text">
                                                    <h5>{tesmnl.Title}</h5>
                                                    <p>{tesmnl.Sub}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                            
                        </div>
                    
                    </div>
                </div>
                <div style={{marginTop:40, textAlign:"center"}}> 
                <Link to="/addreview"> <button className="theme-btn-s2"  style={{color:"#ca9f40", backgroundColor:"black", border:"none", padding:"15px", fontSize:"16px", width:"140px"}} type="submit">Add Review</button></Link>

                </div>
            </div>
        );
    
}

export default Testimonial;