import React from 'react'
import blg1 from '../../images/blog/IMG-20221007-WA0004.jpg'
import blg2 from '../../images/blog/IMG-20221010-WA0001.jpg'
import blg3 from '../../images/blog/IMG-20221221-WA0005.jpg'
import {Link} from 'react-router-dom'

import './style.css'

const BlogSection = (props) => {
    
    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }

     const Blog = [
         {
           blogImg: blg3,
           title:"Welcome To December",
           Thumb:'Season',
           date:'1 Dec 2022',
           link:"#"
         },
         {
           blogImg:  blg1,
           title:"World smile day",
           Thumb:'Celebration',
           date:'10 Oct 2022',
           link:"#"
         },
         {
           blogImg: blg2,
           title:"Special Discount",
           Thumb:'Discount',
           date:'1 Oct 2022',
           link:"#"
         },
     ]
    return(
        <div className="blog-area ptb-100-70">
            <div className="container">
                <div className="col-12">
                    <div className="wpo-section-title">
                        {/* <span>Blog Post</span> */}
                        <h2 style={{color:"#ca9f40"}}>Our Latest News</h2>
                    </div>
                </div>
                <div className="row">
                    {Blog.map((blog, blg) => (
                        <div className="col-lg-4 col-md-6 col-12" key={blg}>
                            <div className="blog-item">
                                <div className="blog-img">
                                    <img src={blog.blogImg} alt=""/>
                                </div>
                                <div className="blog-content">
                                    <ul className="post-meta">
                                        <li><Link onClick={ClickHandler} to={blog.link}>{blog.Thumb}</Link></li>
                                        <li>{blog.date}</li>
                                    </ul>
                                    <h3>{blog.title}</h3>
                                    {/* <Link onClick={ClickHandler} to={blog.link}>Continue</Link> */}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default BlogSection;