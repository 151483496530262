import React, {useState} from 'react';
import Grid from "@material-ui/core/Grid";
import SimpleReactValidator from "simple-react-validator";
import {toast} from "react-toastify";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {Link, withRouter} from "react-router-dom";
import Navbar from '../../components/Navbar2'
import PageTitle from '../../components/pagetitle'
import Image from "../../images/slider/slide-2.jpg"
import axios from "axios"

import './style.scss';

const LoginPage = (props) => {

    const[review, setreview]=React.useState([])

    const [value, setValue] = useState({
        email: 'user@gmail.com',
        password: '123456',
        remember: false,
    });

    const changeHandler = (e) => {
        setValue({...value, [e.target.name]: e.target.value});
        validator.showMessages();
    };

    const rememberHandler = () => {
        setValue({...value, remember: !value.remember});
    };

    const [validator] = React.useState(new SimpleReactValidator({
        className: 'errorMessage'
    }));


    const submitHandler = (e) =>{
        e.preventDefault()
        const email = document.getElementById("emailhere").value
        const name= document.getElementById("namehere").value
        const comment = document.getElementById("commenthere").value

        const getrooms = async () => {
            await axios.post("https://cromer.sisrevolution.com/api/review/addreview/",
                {
    
                }).then(res => {
                    if (res.status === 200) {
                        setreview(res.data)
                    }
                })
        }

        getrooms()

       }
    

    return (
        <div>
        <Navbar/>
        <PageTitle pageTitle={'Add Review'} img={Image} pagesub={'Review'}/> 

        <Grid className="loginWrapper">
            <Grid className="loginForm">
               
                <div className="add-review">
        <div className="room-title">
            <h2>Add Review</h2>
        </div>
        <div className="wpo-blog-single-section">
            {/* <div className="review-text">
                <div className="r-title">
                    <ul>
                        <li><Link to="/room-single"><i className="fi flaticon-star-1"></i></Link></li>
                        <li><Link to="/room-single"><i className="fi flaticon-star-1"></i></Link></li>
                        <li><Link to="/room-single"><i className="fi flaticon-star-1"></i></Link></li>
                        <li><Link to="/room-single"><i className="fi flaticon-star-1"></i></Link></li>
                        <li><Link to="/room-single"><i className="fi flaticon-star-1"></i></Link></li>
                    </ul>
                </div>
            </div> */}
            <div className="review-add">
                <div className="comment-respond">
                    <form onSubmit={submitHandler} method="post" id="commentform" className="comment-form">
                        <div className="form-inputs">
                            
                            <input id="namehere" placeholder="Your Name*" type="text"/>
                            
                            <input id="emailhere" placeholder="Your Email*" type="email"/>

                        </div>
                        <div className="form-textarea">
                            <textarea id="commenthere" placeholder="Your Review"></textarea>
                        </div>
                        {/* <div className="form-check">
                            <div className="shipp pb">
                                <input type="checkbox" id="c2" name="cc"/>
                                <label htmlFor="c2"><span></span>Save my name, email, and website in this browser for the next time I comment.</label>
                            </div>
                        </div> */}
                        <div className="form-submit">
                            <input id="submit" style={{backgroundColor:"black", color:"#ca9f40"}} value="Submit Now" type="submit"/>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
            </Grid>
        </Grid>
        </div>
    )
};

export default withRouter(LoginPage);






