import React from 'react'
import { Link } from 'react-router-dom'
import './style.css'
import Foodimg from "../../images/featured/1.jpg"
import Poolimg from "../../images/featured/2.jpg"
import Relaximg from "../../images/featured/6.jpg"
import Gymimg from "../../images/featured/3.jpg"
import Taxiimg from "../../images/featured/5.jpg"
import Eventsimg from "../../images/featured/4.jpg"
import { ServerStyleSheets } from '@material-ui/core'


const Service = (props) => {

    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }
      
    const serviceWrap = [
        {
           sIcon:Foodimg,
           title:"Delicious Food",
           des:' Enjoy dishes by local restaurateurs or use the fully furnished kitchen to prepare the food of your choice.', 
           link:"/service-single"
        },
        {
           sIcon:Poolimg,
           title:"Swimming Pool",
           des:'Our outdoor Swimming Pool allows you to relax, soak up the African sun and unwind.',           
           link:"/service-single"
        },
        {
           sIcon:Relaximg,
           title:"Relax",
           des:'Schedule a magnificent massage with a local therapist to help you relax and rejuvenate your mind, body and soul.',
           link:"/service-single"
        },
        {
           sIcon:Gymimg,
           title:"Gym",
           des:'Keep up with your fitness routine in our gym furninshed with the best equipment.',
           link:"/service-single"
        },
        {
           sIcon:Taxiimg,
           title:"Airport Transfer",
           des:' We are happy to schedule your airport transfers at a fee. ',
           link:"/service-single"
        },
        {
           sIcon:Eventsimg,
           title:"Events",
           des:' Host your next great event in our courtyard, lobby, poolside or garden and create memorable experiences.',
           link:"/service-single"
        },
    ]

    return(
        <div className="service-area section-padding">
            <div className="container">
                <div className="col-12">
                    <div className="wpo-section-title">
                        <span>Our Services</span>
                        <h2>Available Services to our Guests</h2>
                    </div>
                </div>
                <div className="row">
                    {serviceWrap.map((service, sev) => (
                        <div className="col col-lg-4 col-md-6 custom-grid col-12" key={sev}>
                            <div className="service-item">
                                <div className="service-icon">
                                    <img src={service.sIcon} style={{marginBottom:7}} />
                                </div>
                                <div className="service-text">
                                    <h2>{service.title} {service.title==="Gym"?<span style={{fontSize:"18px"}}>(Coming Soon!)</span>:""}</h2>
                                    <p>{service.des} </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Service;