import React, { useState, useCallback } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap';
import classnames from 'classnames';
import './style.css'
import {Link} from 'react-router-dom'
import rm1 from '../../images/galery/studio/image1.jpg'
import rm2 from '../../images/galery/studio/image2.jpg'
import rm3 from '../../images/galery/studio/image3.jpg'
import rm_3 from '../../images/galery/deluxe/image7.png'
import rm_4 from '../../images/galery/deluxe/image8.jpg'
import rm_5 from '../../images/galery/deluxe/image9.png'


import rm4 from '../../images/galery/studio/image4.jpg'
import rm5 from '../../images/galery/studio/image5.jpg'
import rm6 from '../../images/galery/studio/image6.JPG'
import rm7 from '../../images/garden.jpg'


import rm1_ from '../../images/galery/lobby/image1.JPG'
import rm2_ from '../../images/galery/lobby/image2.JPG'
import rm3_ from '../../images/galery/lobby/image3.jpg'
import rm4_ from '../../images/galery/lobby/image4.jpg'
import rm5_ from '../../images/galery/lobby/image5.jpg'
import rm5__ from '../../images/galery/lobby/image6.png'
import rm5___ from '../../images/galery/lobby/image7.png'
import rm5_6 from '../../images/galery/lobby/image8.png'
import rm5_7 from '../../images/galery/lobby/image9.png'
import rm5_8 from '../../images/galery/lobby/image10.png'
import rm5_9 from '../../images/galery/lobby/image11.png'

import rm1_pool from '../../images/galery/pool/image1.jpg'
import rm2_pool from '../../images/galery/pool/image2.jpg'
import rm3_pool from '../../images/galery/pool/image3.JPG'
import rm4_pool from '../../images/galery/pool/image4.jpg'
import rm5_pool from '../../images/galery/pool/image5.jpg'



import rm8 from '../../images/galery/deluxe/image1.jpg'
import rm9 from '../../images/galery/deluxe/image2.jpg'
import rm10 from '../../images/galery/deluxe/image3.jpg'
import rm11 from '../../images/galery/deluxe/image4.jpg'

import rm12 from '../../images/galery/superior/image1.jpg'
import rm13 from '../../images/galery/superior/image2.jpg'
import rm14 from '../../images/galery/superior/image3.jpg'
import rm15 from '../../images/galery/superior/image4.JPG'

import rm16 from '../../images/galery/executive/image1.jpg'
import rm17 from '../../images/galery/executive/image2.jpg'
import rm18 from '../../images/galery/executive/image3.jpg'
import rm19 from '../../images/galery/executive/image4.jpg'

import "react-image-gallery/styles/css/image-gallery.css"
import ImageGallery from 'react-image-gallery';


const RoomSection = (props) => {

    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if(activeTab !== tab) setActiveTab(tab);
    }


    const Room = [

        {
            RoomImg:rm1,
            RoomHeading:"Studio Apartment",
            RoomCount:'Twin Room',
            Des:"",
            Price:"$90",
            Link:"/room-single_studio",
        },
        {
            RoomImg:rm2,
            RoomHeading:"Deluxe Apartment",
            RoomCount:'Twin Room',
            Des:"",
            Price:"$100",
            Link:"/room-single_deluxe",
        },
        {
            RoomImg:rm3,
            RoomHeading:"Superior Deluxe Apartment",
            RoomCount:'Twin Room',
            Des:"",
            Price:"$170",
            Link:"/room-single_superior",
        },
        ,
        {
            RoomImg:rm4,
            RoomHeading:"Executive Apartment",
            RoomCount:'',
            Des:"",
            Price:"$190",
            Link:"/room-single_executive",
        },

    ]

    const Room2 = [

       
        {
            RoomImg:rm5,
            RoomHeading:"Lobby",
            RoomCount:'Twin Room',
            Des:"About Lobby",
            Price:"$100",
            Link:"/room-single",
        },
        {
            RoomImg:rm6,
            RoomHeading:"Pool",
            RoomCount:'Twin Room',
            Des:"About pool",
            Price:"$100",
            Link:"/room-single",
        },
        {
            RoomImg:rm7,
            RoomHeading:"Garden",
            RoomCount:'Twin Room',
            Des:"About garden",
            Price:"$100",
            Link:"/room-single",
        },

    ]

    const PHOTOS = [rm1,rm2];

    const [isOpen, setIsOpen] = useState(false);

    const images = [
        {
          original: rm1,
          thumbnail: rm1,
          description: 'Studio Apartment Bed'
        },
        {
            original: rm2,
            thumbnail: rm2,
            description: 'Studio Apartment Kitchen'
          },
        {
            original: rm4,
            thumbnail: rm4,
            description: 'Cromer Apartments Shower'
          },{
            original: rm6,
            thumbnail: rm6,
            description: 'Cromer Apartments Cookware'
          },  
          {
          original: rm8,
          thumbnail: rm8,
          description: 'Deluxe Studio Apartment Bed'
        },
        {
            original: rm9,
            thumbnail: rm9,
            description: 'Deluxe Studio Apartment Kitchen'
          },
         {
          original: rm12,
          thumbnail: rm12,
          description: 'Superior Deluxe Apartment Bed'
        },
        {
            original: rm13,
            thumbnail: rm13,
            description: 'Superior Deluxe Apartment Kitchen'
          },
          {
            original: rm14,
            thumbnail: rm14,
            description: 'Cromer Apartments Lavatory'
          }, {
            original: rm_3,
            thumbnail: rm_3,
            description: 'Cromer Apartments Washroom'
          }, {
            original: rm_4,
            thumbnail: rm_4,
            description: 'Cromer Apartments Washroom'
          }
          
          ,{
          original: rm16,
          thumbnail: rm16,
          description: 'Executive Apartment Bed'
        },
        {
            original: rm17,
            thumbnail: rm17,
            description: 'Executive Apartment Living Room'
          },
          {
            original: rm18,
            thumbnail: rm18,
            description: 'Executive Apartment Living Room'
          },{
            original: rm19,
            thumbnail: rm19,
            description: 'Executive Apartment Kitchen'
          }


        ];



    const images_event = [
      {
        original: rm5_7,
        thumbnail: rm5_7,
        description: 'Cromer Apartments Events'
      },
        {
          original: rm5___,
          thumbnail: rm5___,
          description: 'Cromer Apartments Events'
        }, {
          original: rm5_6,
          thumbnail: rm5_6,
          description: 'Cromer Apartments Events'
        },  {
          original: rm5_8,
          thumbnail: rm5_8,
          description: 'Cromer Apartments Events'
        }, {
          original: rm5_9,
          thumbnail: rm5_9,
          description: 'Cromer Apartments Events'
        },
        {
          original: rm5__,
          thumbnail: rm5__,
          description: 'Cromer Apartments Events'
        },
        {
          original: rm1_,
          thumbnail: rm1_,
          description: 'Cromer Apartments Lobby'
        },
        {
            original: rm2_,
            thumbnail: rm2_,
            description: 'Cromer Apartments Lobby'
          },
          {
            original: rm3_,
            thumbnail: rm3_,
            description: 'Cromer Apartments Lobby'
          },
          {
            original: rm4_,
            thumbnail: rm4_,
            description: 'Cromer Apartments Lobby'
          },
          {
            original: rm5_,
            thumbnail: rm5_,
            description: 'Cromer Apartments Garden'
          },
         
      ];

    const images_pool = [
        {
          original: rm1_pool,
          thumbnail: rm1_pool,
          description: 'Cromer Apartments Outdoor Swimming Pool'
        },
      
          {
            original: rm3_pool,
            thumbnail: rm3_pool,
            description: 'Cromer Apartments Outdoor Swimming Pool'
          },
          {
            original: rm4_pool,
            thumbnail: rm4_pool,
            description: 'Cromer Apartments Swimming Lessons Available'
          },
          {
            original: rm5_pool,
            thumbnail: rm5_pool,
            description: 'Cromer Apartments Pool'
          }
         
      ];

      const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [fullscreen, setfullscreen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

     
    return(
        <section style={{marginTop:"-30px"}} className={`Room-area section-padding ${props.rClass}`}>
            <div className="Room-section">
                <div className="container">
                    
                    <div className="row">
                        <div className="col col-xs-12 sortable-gallery">
                            <div className="gallery-filters container">
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '1' })}
                                            onClick={() => { toggle('1'); }}
                                        >
                                          Our Apartments
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '2' })}
                                            onClick={() => { toggle('2'); }}
                                        >
                                            Event Spaces
                                        </NavLink>
                                   
                                   </NavItem>
                                   
                                   <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '3' })}
                                            onClick={() => { toggle('3'); }}
                                        >
                                            Outdoor Swimming Pool
                                        </NavLink>
                                   
                                   </NavItem>
                                </Nav>
                            </div>
                            <div className="gallery-container" >
                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId="1">
                                    <ImageGallery  items={images}  thumbnailPosition="top"/>
                                        {/* {Room.map((room, rm) => (
                                                <div className="grid" key={rm}> 
                                                    <div className="room-item">
                                                        <img src={room.RoomImg} alt="" className="img img-responsive"/>
                                                      
                                                        <small>{room.RoomHeading}</small>
                                                    
                                                    </div>
                                                </div>
                                            ))} */}









                                    </TabPane>
                                 
  <TabPane tabId="2">
                                    <ImageGallery  items={images_event}  thumbnailPosition="top"/>
                                        {/* {Room.map((room, rm) => (
                                                <div className="grid" key={rm}> 
                                                    <div className="room-item">
                                                        <img src={room.RoomImg} alt="" className="img img-responsive"/>
                                                      
                                                        <small>{room.RoomHeading}</small>
                                                    
                                                    </div>
                                                </div>
                                            ))} */}









                                    </TabPane>
                                                                  
  <TabPane tabId="3">
                                    <ImageGallery  items={images_pool}  thumbnailPosition="top"/>
                                        {/* {Room.map((room, rm) => (
                                                <div className="grid" key={rm}> 
                                                    <div className="room-item">
                                                        <img src={room.RoomImg} alt="" className="img img-responsive"/>
                                                      
                                                        <small>{room.RoomHeading}</small>
                                                    
                                                    </div>
                                                </div>
                                            ))} */}









                                    </TabPane>
                                 


                                </TabContent>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default RoomSection;