import React from 'react';
import ContactForm from '../ContactFrom'
import './style.css'

const Contactpage = () => {

    return(
        <div id="Contact" className="contact-area section-padding">
            <div className="container">
                <div className="wpo-contact-info">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="info-item">
                                <div className="info-wrap">
                                    <div className="info-icon">
                                        <i className="fi flaticon-internet"></i>
                                    </div>
                                    <div className="info-text">
                                        <span>Hotel Address</span>
                                    </div>
                                </div>
                                <h2> MQ 3V+7CV Adom Avenue, Second Street (West Legon)</h2>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="info-item">
                                <div className="info-wrap">
                                    <div className="info-icon">
                                        <i className="fi flaticon-email"></i>
                                    </div>
                                    <div className="info-text">
                                        <span>Official Mail</span>
                                    </div>
                                </div>
                                <h2 style={{wordWrap:'break-word'}}><a href='mailto:info@cromerapartments.com' style={{color:"black"}}>info@cromerapartments.com</a></h2>
                                <br/>
                                
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="info-item">
                                <div className="info-wrap">
                                    <div className="info-icon" >
                                        <i className="fi flaticon-null-1" ></i>
                                    </div>
                                    <div className="info-text">
                                        <span>Official Phone</span>
                                    </div>
                                </div>
                                <h2>+233 20 045 5595 / <br/> +233 30 244 6888</h2>
                                <br/>
                               
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="contact-content">
                    <h2>Send A Message</h2>
                    <div className="contact-form">
                        <ContactForm/>
                    </div>
                </div>
                <div className="contact-map">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d508212.8623061769!2d-0.206458!3d5.653245!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2b913a6a4b6449dc!2sCromer%20Apartments%20Ghana!5e0!3m2!1sen!2sus!4v1665495844542!5m2!1sen!2sus" ></iframe>
                </div>
            </div>
        </div>
     )
        
}

export default Contactpage;
