import React from 'react';
import {Link} from 'react-router-dom'
import BlogSidebar from '../BlogSidebar'
import VideoModal from '../../components/ModalVideo'
import './style.css'
import blog1 from '../../images/blog/img-4.jpg'
import blog2 from '../../images/blog/img-5.jpg'
import blog3 from '../../images/blog/img-6.jpg'
import AOS from 'aos';


import axios from "axios"
import swal from 'sweetalert';

const BlogList = (props) => {
    AOS.init({
        // initialise with other settings
        duration : 700
      });
       
      const converter=(data)=>{

   const dt = new Date(data+"")    
const time = dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds();
const day = dt.getUTCDate();
const month = String(dt.getMonth() + 1).padStart(2, '0');
const year = dt.getFullYear();
const date = year + "-" + month + "-" + day;

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

return months[parseInt((date.split("-"))[1])+1] +" "+(date.split("-"))[2]+", "+(date.split("-"))[0] 


      }

    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }

     const [loader, setloader] = React.useState(false);
     const [results, setresults] = React.useState(false);

     const searching=()=>{
        setloader(true)
        var bookingID = document.getElementById("bookingID").value

        const doer = async () => {
               
            await axios.post("https://cromer.sisrevolution.com/api/bookings/getbookingid/",
                {
                    
                    bookingID: bookingID,
                    
                }).then(res => {

                    if (res.status === 200) {

                        setloader(false)
                        setresults(res.data)
                        // swal("Successful Booking", "Thank you for making your reservation at Cromer Apartments", "success");
                if(res.data.length===0){
                    swal("Error", "Please input a corrrect Booking ID", "error");

                }
                    }  else {
                        setloader(false)
                        swal("Error", "Please input a corrrect Booking ID", "error");

                        // setloading(!true)
                        // handleClicksnack()
                        // setmessage("Login Error, please check your login credentials")
                        // setstated("error")
                    }

                })

        }

       doer()



     }

    return(
        <section className="wpo-blog-pg-section section-padding">
            
                    <div className="container">
                        <div className="wpo-blog-content">
                          
                            <div className="post post-text-wrap">
                                <div style={{textAlign:"center"}}>
                                    <h2 style={{color:"black"}}>Track Your Bookings here</h2>
                                    {/* <span></span> */}
                                </div>
                                <div style={{textAlign:"center", marginTop:40}}>

                                <div className="widget search-widget" >
                    <form >
                        <div>
                            <input type="text" id='bookingID' className="form-control" placeholder="Enter Your Booking ID"/>
                            {
                                loader?
                                <button disabled  style={{backgroundColor:"lightgray", color:"gray"}}  >Loading...</button>
:                            <button onClick={searching} type="submit"><i className="ti-search"></i></button>

                            }
                        </div>
                    </form>
                    {console.log(results)}
                    {results!==false? results.length!==0?
<div id='setter' style={{textAlign:"left", marginTop:30, backgroundColor:"white"}}>
  
                                                <div className="room-title">
                                                    <h2 style={{color:"black"}}  data-aos="fade-up">Booking Details</h2>
                                                </div>
                                                <span><b>Room Details</b></span>
                                                <ul>
                                                    <li>Booking ID: <span style={{color:"gray"}}>{results[0].bookingID}</span> </li>
                                                    <li>Booking Status: <span style={{color:"gray"}}>{results[0].status}</span></li>
                                                    <li>Room Type: <span style={{color:"gray"}}>{results[0].room[0].category}</span></li>
                                                    <li>Charge: <span style={{color:"gray"}}>$ {results[0].room[0].price}</span></li>
                                                    <li>Period Of Stay: <span style={{color:"gray"}}>{converter(results[0].checkin[0]) +" to "+ converter(results[0].checkout[0])}</span></li>
                                                   
                                                </ul>  
                                                
                                                <span><b>Personal Details</b></span>
                                                <ul >
                                                    <li>Name: <span style={{color:"gray"}}>{results[0].name}</span> </li>
                                                    <li>Contact: <span style={{color:"gray"}}>{results[0].contact}</span></li>
                                                    <li>Email: <span style={{color:"gray"}}>{results[0].email}</span></li>
                                                    <li>Address: <span style={{color:"gray"}}>{results[0].address}</span></li>
                                                   
                                                </ul>    
                                                
                                             
                                         


</div>
:
<p></p>:""
}

                </div>

                                </div>
                                
                            </div>
                           
                        </div>
                    </div>
                
          
        </section>
     )
        
}

export default BlogList;
