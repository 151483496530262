import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar2'
import PageTitle from '../../components/pagetitle'
import Service from '../../components/Service'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Chat from '../../components/chat'
import Image from "../../images/slider/slide-3.jpg"


const ServicePage =() => {
    return(
        <Fragment>
            <Navbar/>
            <PageTitle pageTitle={'Service'} img={Image} pagesub={'Service'}/> 
            <Service/>
            <Footer/>
            <Chat/>
        </Fragment>
    )
};
export default ServicePage;
