import React, { useEffect } from 'react';
import { Widget, addResponseMessage,addUserMessage,setQuickButtons,toggleWidget,isWidgetOpened } from 'react-chat-widget';

import 'react-chat-widget/lib/styles.css';
import "./index.css"
import logo from '../../images/CA.png';

import axios from "axios"

import io from "socket.io-client";


const dt = new Date()
const time = dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds();
const day = dt.getUTCDate();
const month = String(dt.getMonth() + 1).padStart(2, '0');
const year = dt.getFullYear();
const date = year + "-" + month + "-" + day;

const socket = io.connect("https://nodeserver.cromerapartments.com")

function App() {


  const [room, setroom] = React.useState("room1")
  const [email, setemail] = React.useState(localStorage.getItem("cromer_email")!==undefined && localStorage.getItem("cromer_email")!==null &&  localStorage.getItem("cromer_email"))
  const [chats, setchats] = React.useState([])


  useEffect(() => {
    socket.emit("join_room","cromer" )
    socket.on("receive_cromermessage", (data) => {
      console.log("chat in")
      if(data.email===email){
      addResponseMessage(data.chat.message)
      }
    })
},[socket])





  const firstmessage=()=>{
    console.log(isWidgetOpened())
    if(isWidgetOpened()){

      if(chats.length===0){
        setemail(false)
      addResponseMessage('Please Enter your **Email Address** to start a chat!');
      }

    }

  }


  const handleNewUserMessage = (newMessage) => {
    console.log(`New message incoming! ${newMessage}`);
    // addResponseMessage('Please Enter your **name** and **email** to start a chat!');

  // check if input is an email
  // if yes save in a localStorage
  // and a const ast well
  // check the localstorage and const anytime a message is sent
  // use the email to save data and use as room too

  function ValidateEmail(input) {

    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  
    if (input.match(validRegex)) {
  
      console.log("Valid email address!");
  
      return true;
  
    } else {
  
      console.log("Invalid email address!");
  
      return false;
  
    }
  
  }
  


const addchat = async (email_,newMessage_) => {
  await axios.post("https://cromer.sisrevolution.com/api/chat/addchat/", {
                 email:email_,
                 chat:{sender:email_,message:newMessage_, reply:[], seen:[], date:date, time:time, sentTime:new Date().getTime()},
                 date:date,
                 time:time

              }).then(res => {

          if (res.status === 200) {
            if(chats.length===0){
           setchats(res.data)
           console.log(res.data)

           console.log(chats)
            }

          //    dispatch(update_bookings({
          //     data: res.data
          // }))
          // setselected([])
          //     // setloading(!true)

          //     // swal("", " AY Direct Commission Updated ", "success");


          } else {
              // setloading(!true)
              // swal("", "Error Updating AY Direct Commission", "error");

          }

      })

}


  if((email===false && (localStorage.getItem("cromer_email")===undefined)  ||  (localStorage.getItem("cromer_email")===null && email===false ) )){
   
    if(ValidateEmail(newMessage)){
      addResponseMessage('Please Enter your **name** to continue the chat!');
      setemail(newMessage)
      localStorage.setItem("cromer_email", newMessage)
      addchat(newMessage,[])
    }else{
      addResponseMessage('Please Enter a valid **email** to start a chat!');
    }

  }else{

    setemail(localStorage.getItem("cromer_email"))
    addchat(email, newMessage)
    socket.emit("send_message_tocromer", {email:email,chat:{sender:email,message:newMessage, reply:[], seen:[], date:date, time:time, sentTime:new Date().getTime()}})

  }
 
  };


  if(chats.length!==0){

    for(var i in chats[0].chat){
      if(chats[0].chat[i].sender==="cromer"){
        // console.log(chats[0].chat[i].message)
        addResponseMessage(chats[0].chat[i].message)
      }
      else if( (chats[0].chat[i].message)!==undefined && (chats[0].chat[i].message).length!==0 ){
        // console.log(chats[0].chat[i].message)
        addUserMessage(chats[0].chat[i].message)

      }
    }


  }

  const signout=()=>{
    window.localStorage.clear()
    toggleWidget()
    setemail([])

  }

    return (
      <div className="App">
        <Widget  
          handleToggle={()=>firstmessage()}      
          handleNewUserMessage={handleNewUserMessage}
          profileAvatar={logo}
          title="Cromer Apartments"
          subtitle={<button style={{color:"gold", borderColor:"gold", backgroundColor:"black"}} onClick={signout}>End Chat</button>}
          typing="true"
        />
      </div>
    );
}

export default App;