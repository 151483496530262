import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar2'
import PageTitle from '../../components/pagetitle'
import BlogList from '../../components/gallery'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Chat from '../../components/chat'
import Image from "../../images/user.JPG"

const ReservationPage =() => {
    return(
        <Fragment>
            <Navbar/>
            <PageTitle pageTitle={'Gallery'} img={Image} pagesub={'Gallery'}/> 
            <BlogList/>
            <Footer footerClass={'wpo-ne-footer-2'}/>
            <Chat/>
        </Fragment>
    )
};
export default ReservationPage;

