import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar2'
import PageTitle from '../../components/pagetitle'
import BlogList from '../../components/Reservation'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Chat from '../../components/chat'
import Image from "../../images/slider/slide-2.jpg"

const ReservationPage =() => {
    return(
        <Fragment>
            <Navbar/>
            <PageTitle pageTitle={'My Reservations'} img={Image} pagesub={'My Reservation'}/> 
            <BlogList/>
            <Footer footerClass={'wpo-ne-footer-2'}/>
            <Chat/>
        </Fragment>
    )
};
export default ReservationPage;

