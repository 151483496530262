
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Images from '../../images/room/img-1.jpg';
import Image1 from '../../images/room/studio/image1.jpg';
import Image5 from '../../images/room/deluxe/image1.jpg';
import Image2 from '../../images/room/deluxe/courtyard/image1.jpg';
import Image3 from '../../images/room/superior/image1.jpg';
import Image4 from '../../images/room/executive/image1.jpg';
import { Link } from 'react-router-dom'

import './style.css'
import axios from 'axios'
import zIndex from "@material-ui/core/styles/zIndex";

const dt = new Date()
const time = dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds();
const day = dt.getUTCDate();
const month = String(dt.getMonth() + 1).padStart(2, '0');
const year = dt.getFullYear();
const date = year + "-" + month + "-" + day;


const SearchSection = (props) => {

    const imagelist={"Studio Apartment":Image1,"Deluxe Studio Poolside":Image5,"Deluxe Studio Courtyard":Image2,"Superior Deluxe Apartment":Image3,"Executive Apartment":Image4,}

    const [responser,  setresponser] = useState("No room available");
    const [startDate,  setStartDate] = useState(new Date());
    const [startDates,  setStartDates] = useState(new Date());
    const [quest,  setquest] = useState(0);
    const [roomdata,  setroomdata] = useState([]);
    const [filtereddata,  setfiltereddata] = useState([]); 

    const [show, setShow] = useState(false);  

    const SubmitHandler = (e) =>{
        e.preventDefault()
     }

     const getrooms = async () => {
        await axios.post("https://cromer.sisrevolution.com/api/rooms/getrooms/",
            {

            }).then(res => {
                console.log(res)

                if (res.status === 200) {
                    setroomdata(res.data)
                    console.log(res)
                }
            })
    }

    React.useEffect(()=>{

        getrooms()

    },[startDate, startDates])

const changequest =(e)=>{
setquest(e.target.value)

}


const checker =()=>{
    var available=[]
    var startdate=new Date(startDate+"").getTime()
    var enddate_=new Date(startDates+"").getTime()
    var quest_ = parseInt(quest) 
    var datechecker_start= startdate-new Date(date+"").getTime()
    var datechecker_end= enddate_-startdate


   
    if(roomdata.length !==0){
    var newer = roomdata.filter(bug=>parseInt(bug.max)>=quest_)
    if(newer.length!==0){
    for(var j in newer){
    console.log(parseInt(newer[j].rooms))
        if((datechecker_start>=0 && datechecker_end>=0)){
          console.log("entered")
            if(newer[j].occupant.length!==0){
                var finder =[...newer[j].occupant]
                var searcher = finder.filter(bug=> startdate-new Date(bug.enddate).getTime()<0 )
                console.log(searcher)
                if(searcher.length<= parseInt(newer[j].rooms)){
                available.push(newer[j])
                console.log(available)
            }
        }if(newer[j].occupant.length===0){
            available.push(newer[j]) 
        }
    } else if (datechecker_start<0 || datechecker_end<0){
        available=[]
    }else {
        // available.push(newer[j])
    }

    }

}else{
    setresponser("Maximum number of Guests exceeded")
}
}
        

        
        
    setfiltereddata(available)
    console.log(available)
    
    setShow(true)
   
}



    return(
<>

<Modal

       style={{zIndex:"9999"}}
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-100w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton   style={{zIndex:"1111"}}>
          <Modal.Title id="example-custom-modal-styling-title">
            Available Rooms
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        
  { filtereddata !==undefined && filtereddata.length!==0? filtereddata.map((list, index)=>
        <Container style={{marginTop:8}} key={index}>

        <Row>
        <Col xs={5}> 
        <img src={imagelist[list.category]} width="100%" />
         </Col>
        <Col xs={7}> 
       <b>{list.category}</b><br/>
       {/* <button className="theme-btn-s2" onClick={()=>checker()} type="submit">Check Availability</button> */}

      <Link  to={{ pathname:"/room-single", state : {list, startDate:startDate, enddate:startDates}}} ><button className="theme-btn-s2" variant="primary" style={{background:"black", color:"#ca9f40"}}> <b>$ {list.price}   </b> / Night</button> </Link> 
      <br/>
        <small>Maximum Number of persons : {list.max}</small><br/>
        <small><b>Features</b></small>
        <ul>
            {list.features.map((list,index)=>
              <li>
                <small> {list+"  "} </small>
                
              </li>
            )
          
}
        </ul>
        
         </Col>
      </Row>
      
    </Container>
  ): responser
  
}

        </Modal.Body>
      </Modal>

        <div className={`wpo-select-section upper ${props.selectClass}`}>


            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="wpo-select-wrap">
                            <div className="wpo-select-area">
                                <form onSubmit={SubmitHandler} className="clearfix">
                                    <div className="select-sub">
                                        <span><i className="fi flaticon-calendar"></i>Check - In</span>
                                        <div className="form-group">
                                            <div id="filterDate">
                                                <div className="input-group date" data-date-format="dd.mm.yyyy">
                                                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                                                    <div className="input-group-addon">
                                                        <span className="ti-angle-down"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="select-sub">
                                        <span><i className="fi flaticon-calendar"></i>Check - out</span>
                                        <div className="form-group">
                                            <div id="filterDate2">
                                                <div className="input-group date" data-date-format="dd.mm.yyyy">
                                                    <DatePicker selected={startDates} onChange={(date) => setStartDates(date)} />
                                                    <div className="input-group-addon">
                                                        <span className="ti-angle-down"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="select-sub">
                                        <span> <i className="fi flaticon-user"></i> Guests</span>
                                        <select  value={quest} onChange={changequest} className="select wide">
                                        <option >01</option>
                                            <option >02</option>
                                            <option >03</option>
                                            <option >04</option>
                                            <option >05</option>
                                            <option >06</option>
                                            <option >07</option>
                                        </select>
                                    </div>
                                    <div className="select-sub">
                                        <span> <i className="fi flaticon-user"></i> CHILDREN</span>
                                        <select className="select wide">
                                            <option >0</option>
                                            <option >01</option>
                                            <option >02</option>
                                            <option >03</option>
                                            <option >04</option>
                                            <option >05</option>
                                            <option >06</option>
                                            <option >07</option>
                                        </select>
                                    </div>
                                    <div className="select-sub">
                                        <button className="theme-btn-s2" onClick={()=>checker()} style={{color:"#ca9f40", backgroundColor:"black", border:"none", padding:"15px", fontSize:"16px", width:"100%"}} type="submit">Check  Availability</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        </>
    )
}

export default SearchSection;