import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../images/logo.png'
import ft1 from '../../images/footer/img-1.jpg'
import ft2 from '../../images/footer/img-2.jpg'
import axios from 'axios'
import './style.css'
import swal from 'sweetalert';

const Footer = (props) => {
    const [loader, setloader] = React.useState(false);

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    const SubmitHandler = (e) => {
        e.preventDefault()
        setloader(true)
        var email = document.getElementById("email").value

        const doer = async() => {

            await axios.post("https://cromer.sisrevolution.com/api/news/register/", {


                email: email,


            }).then(res => {

                if (res.status === 200) {

                    setloader(false)
                    swal("Successful", "You will be receiving our Newsletters", "success");

                } else {
                    setloader(false)

                }

            })

        }

        doer()



    }

    return ( <
        footer className = "wpo-site-footer" >
        <
        div className = "wpo-upper-footer" >
        <
        div className = "wpo-footer-top" >
        <
        div className = "container" >
        <
        div className = "row" >
        <
        div className = "col-lg-6 col-md-6 col-sm-12 col-12 custom-grid" >
        <
        div className = "logo widget-title" >
        <
        img src = { Logo }
        alt = "logo" / >
        <
        /div> <
        /div> <
        div className = "col-lg-6 col-md-6 col-sm-12 col-12 custom-grid" >
        <
        ul >
        <
        li > < a
        href = "https://www.facebook.com/profile.php?id=100081814431151" > < i className = "ti-facebook" > < /i></a > < /li> <
        li > < Link onClick = { ClickHandler }
        to = "/" > < i className = "ti-twitter-alt" > < /i></Link > < /li> <
        li > < a onClick = { ClickHandler }
        href = "https://www.instagram.com/cromer__apartments/" > < i className = "ti-instagram" > < /i></a > < /li> 
        <
        /ul> <
        /div> <
        /div> <
        /div> <
        /div> <
        div className = "footer-middle" >
        <
        div className = "container" >
        <
        div className = "row" >
        <
        div className = "col col-lg-6 col-md-6 col-sm-12 col-12" >
        <
        div className = "widget market-widget wpo-service-link-widget" >
        <
        div className = "widget-title" >
        <
        h3 > Contact Us< /h3> <
        /div> <
        p > < /p> <
        div className = "contact-ft" >
        <
        ul >
        <
        li > < i className = "fi ti-location-pin" >  < /i> MQ 3V+7CV Adom Avenue, Second Street (West Legon, Accra) </li >
        <
        li > < i className = "fi ti-mobile" > < /i>020 045 5595 | 030 244 6888</li >
        <
        li > < i className = "fi flaticon-email" >  < /i><a href="mailto:info@cromerapartments.com" style={{color:"white"}}>info@cromerapartments.com</a></li >
        <
        /ul> <
        /div> <
        /div> <
        /div> 
        
        

        <div className = "col col-lg-3 col-md-6 col-sm-12 col-12" >
        <div className = "widget link-widget" >
        <div className = "widget-title" >
        <h3> Useful Links </h3> </div> <ul>
        <li > < Link onClick = { ClickHandler }
        to = "/about" > About Us < /Link></li >
    
        <
        li > < Link onClick = { ClickHandler }
        to = "/contact" > Contact Us < /Link></li >
        <
        li > < Link onClick = { ClickHandler }
        to = "/service" > Services < /Link></li >
        <
        /ul> <
        /div> <
        /div> <
        div className = "col col-lg-3 col-md-6 col-sm-12 col-12" >
        <
        div className = "widget newsletter-widget" >
        <
        div className = "widget-title" >
        <
        h3 > STAY CONNECTED  </h3> <
        /div> <
        form onSubmit = { SubmitHandler } >
        <
        div className = "input-1" >
        <
        input type = "email"
        id = 'email'
        className = "form-control"
        placeholder = "Email Address *"
        required = "" / >
        <
        /div> <
        div className = "submit clearfix" > {
            loader ?
            <
            button disabled type = "submit"
            style = {
                { backgroundColor: "lightgray" } } > Loading... < i className = "ti-angle-right" > < /i></button >

            :
                <
                button type = "submit"
            style = {
                { backgroundColor: "goldenrod", color: "black" } } > subscribe < i className = "ti-angle-right"
            style = {
                { backgroundColor: "goldenrod" } } > < /i></button >

        } <
        /div> <
        /form> <
        /div> <
        /div> <
        /div> <
        /div> <
        /div> <
        /div>

        <
        div className = "wpo-lower-footer" >
        <
        div className = "container" >
        <
        div className = "row" >
        <
        div className = "col col-lg-6 col-md-6 col-12" >
        <
        div className = "term" > {
            /* <ul>
                                                <li><Link onClick={ClickHandler} to="/">Privecy Policy</Link></li>
                                                <li><Link onClick={ClickHandler} to="/">Terms & Condition</Link></li>
                                                <li><Link onClick={ClickHandler} to="/">Cookies</Link></li>
                                            </ul> */
        } </div> <
        /div> <
        div className = "col col-lg-6 col-md-6 col-12" >
        <
        p className = "copyright" > &copy; 2022 Cromer Apartment.All rights reserved < /p> <
        /div> <
        /div> <
        /div> <
        /div> <
        /footer>
    )
}

export default Footer;