
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Images from '../../../images/room/img-1.jpg';
import Image1 from '../../../images/room/studio/image1.jpg';
import Image5 from '../../../images/room/deluxe/image1.jpg';
import Image2 from '../../../images/room/deluxe/courtyard/image1.jpg';
import Image3 from '../../../images/room/superior/image1.jpg';
import Image4 from '../../../images/room/executive/image1.jpg';
import { Link } from 'react-router-dom'
import Form from 'react-bootstrap/Form';
import swal from 'sweetalert';
import Card from "../../card"

import './style.css'
import axios from 'axios'
import zIndex from "@material-ui/core/styles/zIndex";


const dt = new Date()
const time = dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds();
const day = dt.getUTCDate();
const month = String(dt.getMonth() + 1).padStart(2, '0');
const year = dt.getFullYear();
const date = year + "-" + month + "-" + day;



const SearchSection = (props) => {
    const imagelist={"Studio Apartment":Image1,"Deluxe Studio Apartment":Image2,"Superior Deluxe Apartment":Image3,"Executive Apartment":Image4,}

    const [startDate,  setStartDate] = useState(new Date());
    const [startDates,  setStartDates] = useState(new Date());
    const [quest,  setquest] = useState(0);
    const [roomdata,  setroomdata] = useState([]);
    const [filtereddata,  setfiltereddata] = useState([]);
    const [pay,  setpay] = useState("no");

    const [response, setresponse] = useState("No room available");
    const [show1, setShow1] = useState(false);
    const [show, setShow] = useState(false);
    const [loader, setloader] = React.useState(false);
    const [name_ , setname_] = React.useState("");
    const [contact_,  setcontact_] = React.useState("");
    const [address_,  setaddress_] = React.useState("");
    const [email__,  setemail__] = React.useState("no");




    const SubmitHandler = (e) =>{
        e.preventDefault()
     }

     const getrooms = async () => {
        await axios.post("https://cromer.sisrevolution.com/api/rooms/getrooms/",
            {

            }).then(res => {
                if (res.status === 200) {
                    setroomdata(res.data.filter(bug=>bug.category==="Superior Deluxe Apartment"))
                }
            })
    }
    React.useEffect(()=>{

        getrooms()

    },[startDate, startDates])

const changequest =(e)=>{
setquest(e.target.value)

}


const checker =()=>{
    var available=[]
    var startdate=new Date(startDate+"").getTime()
    var enddate_=new Date(startDates+"").getTime()
    var quest_ = parseInt(quest) 
    var datechecker_start= startdate-new Date(date+"").getTime()
    var datechecker_end= enddate_-startdate


    var newer = roomdata.filter(bug=>parseInt(bug.max)>=quest_)
    if(roomdata.length !==0){

    if(newer.length>0){
    for(var j in newer){
    console.log(parseInt(newer[j].rooms))
        if((datechecker_start>=0 && datechecker_end>=0)){
          console.log("entered")
            if(newer[j].occupant.length!==0){
                var finder =[...newer[j].occupant]
                var searcher = finder.filter(bug=> startdate-new Date(bug.enddate).getTime()<0 )
                console.log(searcher)
                if(searcher.length<= parseInt(newer[j].rooms)){
                available.push(newer[j])
                console.log(available)
            }
        }if(newer[j].occupant.length===0){
            available.push(newer[j]) 
        }
    } else if (datechecker_start<0 || datechecker_end<0){
        available=[]
    }else {
        // available.push(newer[j])
    }

    }

}else{
    setresponse("Maximum number of Guests exceeded")
}
}
        

        
        
    setfiltereddata(available)
    console.log(available)
    
    setShow(true)
   
}



const booknow=(e)=>{
    e.preventDefault()
        setloader(true)
            const name = document.getElementById("name").value
            const contact = document.getElementById("contact").value
            const address = document.getElementById("address").value
            const email_ = document.getElementById("email_").value
    
            const doer = async () => {
               
                await axios.post("https://cromer.sisrevolution.com/api/bookings/newbooking/",
                    {
                        
                        roomid: filtereddata[0]._id,
                        name: name,
                        contact: contact,
                        email: email_,
                        address: address,
                        room: filtereddata[0],
                        startdate: startDate, 
                        enddate: startDates
    
                    }).then(res => {
    
                        if (res.status === 200) {
    
    
                            setloader(false)
                            swal("Successful Booking", "Thank you for making your reservation at Cromer Apartments", "success");
                    
    
                        }  else {
                            setloader(false)
    
                            // setloading(!true)
                            // handleClicksnack()
                            // setmessage("Login Error, please check your login credentials")
                            // setstated("error")
                        }
    
                    })
    
            }
    
           doer()
    
    
       
    
    
       }
    
    

   const paynow=()=>{
    const name = setname_(document.getElementById("name").value)
    const contact = setcontact_(document.getElementById("contact").value)
    const address = setaddress_(document.getElementById("address").value)
    const email_ = setemail__(document.getElementById("email_").value)

    setpay("yes")
}


const form_ =()=>{

    return(
        <form onSubmit={booknow}>
        <br/>
         <Form.Control required id="name" type="text" placeholder="Name" />
        <br/>
        
         <Form.Control required id="contact" type="tel" placeholder="Contact" />
        <br/>
        
         <Form.Control required id="email_" type="email" placeholder="Email" />
        <br/>
        
         <Form.Control required id="address" type="address" placeholder="Address" />
        <br/>
        <div style={{textAlign:"center"}}>
       {loader?
        <button disabled style={{backgroundColor:"lightgray", color:"gray"}} className="theme-btn-s2" >Loading...</button>
        :
        <button type="submit" style={{backgroundColor:"black", color:"#e0a403"}} className="theme-btn-s2" >Book Now</button>
       }
       <br/>
        </div>
        </form>
    )

   }



   const setter=()=>{
    setShow(false)
    setShow1(true)
   }





    return(
<>

<Modal

       style={{zIndex:"9999"}}
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-100w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton   style={{zIndex:"1111"}}>
          <Modal.Title id="example-custom-modal-styling-title">
            Available Rooms
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        
       
  { filtereddata.length!==0? filtereddata.map((list, index)=>
        <Container style={{marginTop:8}} key={index}>

        <Row>
        <Col xs={5}> 
        <img src={imagelist[list.category]} width="100%" />
         </Col>
        <Col xs={7}> 
       <b>{list.category}</b><br/>
       {/* <button className="theme-btn-s2" onClick={()=>checker()} type="submit">Check Availability</button> */}

      <button style={{backgroundColor:"black", color:"#e0a403"}} onClick={()=>setter()}  className="theme-btn-s2" variant="primary"> <b>$ {list.price}   </b> / Night</button>  <br/>
        <small>Maximum Number of persons : {list.max}</small><br/>
        <small><b>Features</b></small>
        <ul>
            {list.features.map((list,index)=>
              <li>
                <small> {list+"  "} </small>
                
              </li>
            )
          
}
        </ul>
        
         </Col>
      </Row>
      
    </Container>
  ): response
}

        </Modal.Body>
      </Modal>



      <Modal

style={{zIndex:"9999"}}
 show={show1}
 onHide={() => setShow1(false)}
 dialogClassName="modal-100w"
 aria-labelledby="example-custom-modal-styling-title"
>
 <Modal.Header closeButton   style={{zIndex:"1111"}}>
   <Modal.Title id="example-custom-modal-styling-title">
   {pay==="no"?"Book A Room": "Make Payment"}
   </Modal.Title>
 </Modal.Header>
 <Modal.Body>
 <Container> 
    
    



 {pay==="no"?form_():<div> <Card/>    <div style={{textAlign:"center"}}>
            {loader?
                                                    <button disabled onClick={()=>setShow(true)} style={{backgroundColor:"gray"}} className="theme-btn-s2" type="submit">Loading...</button>
        :
        // <button type="submit" style={{backgroundColor:"black", color:"#e0a403"}} className="theme-btn-s2" type="submit">Make Payment</button>

        <button onClick={booknow}  style={{backgroundColor:"black", color:"#e0a403"}} className="theme-btn-s2" >Book A Room</button>
        }
        </div> 
 </div>}


 </Container>



 </Modal.Body>
</Modal>

        <div className={`wpo-select-section ${props.selectClass}`}>


            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="wpo-select-wrap">
                            <div className="wpo-select-area">
                                <form onSubmit={SubmitHandler} className="clearfix">
                                    <div className="select-sub">
                                        <span><i className="fi flaticon-calendar"></i>Check - In</span>
                                        <div className="form-group">
                                            <div id="filterDate">
                                                <div className="input-group date" data-date-format="dd.mm.yyyy">
                                                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                                                    <div className="input-group-addon">
                                                        <span className="ti-angle-down"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="select-sub">
                                        <span><i className="fi flaticon-calendar"></i>Check - out</span>
                                        <div className="form-group">
                                            <div id="filterDate2">
                                                <div className="input-group date" data-date-format="dd.mm.yyyy">
                                                    <DatePicker selected={startDates} onChange={(date) => setStartDates(date)} />
                                                    <div className="input-group-addon">
                                                        <span className="ti-angle-down"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="select-sub">
                                        <span> <i className="fi flaticon-user"></i> Guests</span>
                                        <select  value={quest} onChange={changequest} className="select wide">
                                        <option >01</option>
                                            <option >02</option>
                                            <option >03</option>
                                            <option >04</option>
                                            <option >05</option>
                                            <option >06</option>
                                            <option >07</option>
                                        </select>
                                    </div>
                                    <div className="select-sub">
                                        <span> <i className="fi flaticon-user"></i> CHILDREN</span>
                                        <select className="select wide">
                                            <option >0</option>
                                            <option >01</option>
                                            <option >02</option>
                                            <option >03</option>
                                            <option >04</option>
                                            <option >05</option>
                                            <option >06</option>
                                            <option >07</option>
                                        </select>
                                    </div>
                                    <div className="select-sub">
                                    <button className="theme-btn-s2" onClick={()=>checker()} style={{color:"#ca9f40", backgroundColor:"black", border:"none", padding:"15px", fontSize:"16px", width:"100%"}} type="submit">Check  Availability</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        </>
    )
}

export default SearchSection;