import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar2'
import PageTitle from '../../components/pagetitle'
import About from '../../components/about'
import Features from '../../components/Features'
import RoomSection from '../../components/RoomSection'
import Counter from '../../components/Counter'
import Testimonial from '../../components/Testimonial'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Data from './data'
import Chat from '../../components/chat'
import Image from "../../images/slider5.jpg"

const AboutPage =() => {
    return(
        <Fragment>
            <Navbar/>
            <PageTitle pageTitle={'About Us'}  img={Image} pagesub={'About'}/> 
            <About/>

            <Data/>
            <br/>
            <br/>
            <br/>
            {/* <RoomSection/> */}
            {/* <Counter/> */}
            {/* <Testimonial/> */}
            <Footer/>
            <Chat/>
        </Fragment>
    )
};
export default AboutPage;
