import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar2'
import PageTitle from '../../components/pagetitle'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import RoomSingleSection from '../../components/RoomSingleSection'
import Chat from '../../components/chat'
import Image from "../../images/room/studio/banner.jpg"



const RoomSinglePage =() => {
    return(
        <Fragment>
            <Navbar/>
            <PageTitle pageTitle={'Continue with your booking'} img={Image} pagesub={'Enjoy your stay'}/> 
            <RoomSingleSection/>
            <Footer/>
            <Chat/>
        </Fragment>
    )
};
export default RoomSinglePage;
