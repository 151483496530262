import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar2'
import PageTitle from '../../components/pagetitle'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import RoomSingleSection from '../../components/RoomSingleSection_superior'
import Chat from '../../components/chat'
import Image from "../../images/sup.jpg"


const RoomSinglePage =() => {
    return(
        <Fragment>
            <Navbar/>
            <PageTitle pageTitle={'Superior Deluxe Apartment'} img={Image} pagesub={'Enjoy Your Stay'}/> 
            <RoomSingleSection/>
            <Footer/>
            <Chat/>
        </Fragment>
    )
};
export default RoomSinglePage;
