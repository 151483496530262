import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import rm1 from '../../../images/room/deluxe/image1.jpg'
import rm2 from '../../../images/room/studio/image1.jpg'
import rm3 from '../../../images/room/executive/image1.jpg'
import rm4 from '../../../images/room/superior/image1.jpg'



import rm1_deluxe from '../../../images/room/deluxe/image1.jpg'
import rm2_deluxe from '../../../images/room/deluxe/image2.jpg'
import rm3_deluxe from '../../../images/room/deluxe/image3.jpg'
import rm4_deluxe from '../../../images/room/deluxe/image4.jpg'
import rm5_deluxe from '../../../images/room/deluxe/courtyard/img1.JPG'
import rm6_deluxe from '../../../images/room/deluxe/courtyard/img2.JPG'
import rm7_deluxe from '../../../images/room/deluxe/courtyard/img3.JPG'
import rm8_deluxe from '../../../images/room/deluxe/courtyard/img4.JPG'


import rm1_executive from '../../../images/room/executive/image1.jpg'
import rm2_executive from '../../../images/room/executive/image2.jpg'
import rm3_executive from '../../../images/room/executive/image3.jpg'
import rm4_executive from '../../../images/room/executive/image4.jpg'
import rm5_executive from '../../../images/room/executive/image5.jpg'
import rm6_executive from '../../../images/room/executive/image6.JPG'

import rm1_studio from '../../../images/room/studio/image1.jpg'
import rm2_studio from '../../../images/room/studio/image2.jpg'
import rm3_studio from '../../../images/room/studio/image3.jpg'
import rm4_studio from '../../../images/room/studio/image4.jpg'
import rm5_studio from '../../../images/room/studio/image5.jpg'
import rm6_studio from '../../../images/room/studio/image6.JPG'

import rm1_sup from '../../../images/room/superior/image1.jpg'
import rm2_sup from '../../../images/room/superior/image2.jpg'
import rm3_sup from '../../../images/room/superior/image3.jpg'
import rm4_sup from '../../../images/room/superior/image5.jpg'




const Rooms =(props)=> {

const category = props.data.list.category
    
        var settings = {
            dots: false,
            arrows: true,
            speed: 3000,
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2500,
            responsive: [
                {
                    breakpoint: 1440,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        infinite: true,
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        infinite: true,
                    }
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };


var image1=""
var image2=""
var image3=""
var image4=""

console.log(category)

if(category==="Studio Apartment"){

    image1 = rm1_studio
    image2 = rm2_studio
    image3 = rm3_studio
    image4 = rm4_studio

}
else if(category==="Deluxe Studio Courtyard"){
    image2 = rm6_deluxe
    image3 = rm7_deluxe
    image1 = rm5_deluxe
    image4 = rm8_deluxe


}else if(category==="Deluxe Studio Poolside"){
    image1 = rm1_deluxe
    image2 = rm2_deluxe
    image3 = rm3_deluxe
    image4 = rm4_deluxe
}
else if(category==="Superior Deluxe Apartment"){
    image1 = rm1_sup
    image2 = rm2_sup
    image3 = rm3_sup
    image4 = rm4_sup
}else{
    image1 = rm1_executive
    image2 = rm2_executive
    image3 = rm3_executive
    image4 = rm4_executive
}



        return (
            <div className="room-slide-area section-padding">
            <div className="Room-carousel">
                <Slider {...settings}>
                    <div className="item" >
                        <div className="destination-item">
                            <div className="destination-img" style={{margin:"8px"}}>
                                <img src={image4} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="item" >
                        <div className="destination-item">
                            <div className="destination-img" style={{margin:"8px"}}>
                                <img src={image3} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="item" >
                        <div className="destination-item">
                            <div className="destination-img" style={{margin:"8px"}}>
                                <img src={image2} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="item" style={{margin:"5px"}}>
                        <div className="destination-item">
                            <div className="destination-img">
                                <img src={image1} alt=""/>
                            </div>
                        </div>
                    </div>
                </Slider>
            </div>
        </div>
        );
    
}

export default Rooms;