
import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import axios from "axios"
import swal from 'sweetalert';
import Card from "../../card"

const SearchSection = (props) => {
    const submitHandler = (e) =>{
        e.preventDefault()
       }

       const [name_ , setname_] = React.useState("");
       const [contact_,  setcontact_] = React.useState("");
       const [address_,  setaddress_] = React.useState("");
       const [email__,  setemail__] = React.useState("no");
   
   
       const [show, setShow] = React.useState(false);
       const [loader, setloader] = React.useState(false);
       const [pay,  setpay] = React.useState("no");
   



       console.log(props)


       const booknow=()=>{

        setloader(true)
            const name = name_
            const contact = contact_
            const address = address_
            const email_ = email__
    
            const doer = async () => {
               
                await axios.post("https://cromer.sisrevolution.com/api/bookings/newbooking/",
                    {
                        
                        roomid: props.data.list._id,
                        name: name,
                        contact: contact,
                        email: email_,
                        address: address,
                        room: props.data.list,
                        startdate: props.data.startDate, 
                        enddate: props.data.enddate

                    }).then(res => {
    
                        if (res.status === 200) {
    

                            setloader(false)
                            swal("Successful Booking", "Thank you for making your reservation at Cromer Apartments", "success");
                    
    
                        }  else {
                            setloader(false)

                            // setloading(!true)
                            // handleClicksnack()
                            // setmessage("Login Error, please check your login credentials")
                            // setstated("error")
                        }
    
                    })
    
            }
    
           doer()
    
    
       


       }


       const paynow=()=>{
        const name = setname_(document.getElementById("name").value)
        const contact = setcontact_(document.getElementById("contact").value)
        const address = setaddress_(document.getElementById("address").value)
        const email_ = setemail__(document.getElementById("email_").value)

        setpay("yes")
    }
    
    
       const form_ =()=>{
    
        return(
            <form onSubmit={()=>booknow()}>
            <br/>
             <Form.Control required id="name" type="text" placeholder="Name" />
            <br/>
            
             <Form.Control required id="contact" type="tel" placeholder="Contact" />
            <br/>
            
             <Form.Control required id="email_" type="email" placeholder="Email" />
            <br/>
            
             <Form.Control required id="address" type="address" placeholder="Address" />
            <br/>
            <div style={{textAlign:"center"}}>
           
            <button type="submit" style={{backgroundColor:"black", color:"#e0a403"}} className="theme-btn-s2" >Book Now</button>
    
           
            </div>
            </form>
        )
    
       }
    



    return(
   

        <div className="wpo-select-section-3 container upper"  >
                 <Modal

style={{zIndex:"9999"}}
 show={show}
 onHide={() => setShow(false)}
 dialogClassName="modal-100w"
 aria-labelledby="example-custom-modal-styling-title"
>
 <Modal.Header closeButton   style={{zIndex:"1111"}}>
   <Modal.Title id="example-custom-modal-styling-title">
   {pay==="no"?"Book A Room": "Make Payment"}
   </Modal.Title>
 </Modal.Header>
 <Modal.Body>
 <Container> 
    
    
{pay==="no"?form_():<div> <Card/>    <div style={{textAlign:"center"}}>
            {loader?
                                                    <button disabled onClick={()=>setShow(true)} style={{backgroundColor:"gray"}} className="theme-btn-s2" type="submit">Loading...</button>
        :
        // <button type="submit" style={{backgroundColor:"black", color:"#e0a403"}} className="theme-btn-s2" type="submit">Make Payment</button>

        <button onClick={booknow}  style={{backgroundColor:"black", color:"#e0a403"}} className="theme-btn-s2" >Book A Room</button>
        }
        </div> 
 </div>}





    

 </Container>



 </Modal.Body>
</Modal>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="wpo-select-wrap">
                            <div className="wpo-select-area">
                                <form onSubmit={submitHandler} className="clearfix">
                                    <div className="select-sub">
                                        <span>BEDS</span>
                                        <h2>1 Bed</h2>
                                    </div>
                                    {/* <div className="select-sub">
                                        <span>ROOM SIZE</span>
                                        <h2>870 sq ft / 132 sq m</h2>
                                    </div> */}
                                    <div className="select-sub">
                                        <span>OCCUPANCY</span>
                                        <h2>2 Adults</h2>
                                    </div>
                                   
                                    <div className="select-sub">
                                        <h5>Price <span>${props.data.list.price}</span> / Night</h5>
                                        <button onClick={()=>setShow(true)} style={{color:"#ca9f40", backgroundColor:"black"}}  className="theme-btn-s2" type="submit">Book Now</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    )
}

export default SearchSection;