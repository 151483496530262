import React, { Component } from "react";
import Slider from "react-slick";
import {Link} from 'react-router-dom'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './style.css'
import AOS from 'aos'
import Vid from "../../images/opener.mp4"
import Vid_pool from "../../images/pool.mp4"



class Hero extends Component {
  
    render() {
        var settings = {
            dots: false,
            arrows: false,
            speed: 1600,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 3000,
            fade: true
        };
        AOS.init()
        return (

            <section className={`hero ${this.props.heroClass}`}>
                <div className="hero-slider">
                    <Slider {...settings}>
                       
                      
                        <div className="slide">
                            <div className="slide-inner" >
                            <video autoPlay loop muted id="videos" >
            <source src={Vid} type='video/mp4' />
    </video>
                                <div className="container">
                                    <div className="row">
                                        <div className="col col-lg-8 col-md-12 col-12 slide-caption">
                                        <br/>
                                            <br/>
                                            <div className="slide-title">
                                                <h2 > <span style={{fontSize:"70%"}}> </span> <span style={{backgroundColor:"#f7c33f", color:"black", paddingLeft:"10px", paddingRight:"10px", paddingTop:"-10px", borderTopRightRadius:8, borderTopLeftRadius:8}}>Cromer</span> <span style={{backgroundColor:"black", color:"#f7c33f", paddingLeft:"10px", paddingRight:"10px", borderTopRightRadius:8, borderBottomLeftRadius:8, borderBottomRightRadius:8}}> Apartments</span> </h2>
                                            </div>
                                            <div className="slide-subtitle">
                                                <p style={{color:"white", fontFamily:"cursive" , fontSize:"25px"}} data-aos="fade-down">A home away from home</p>
                                            </div>
                                            <p className="nothing"></p>
                                            {/* <div className="btns booker">
                                                <Link to="/room" className="theme-btn" style={{backgroundColor:"white",color:"black"}}>Book A Room</Link>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                      
                    </Slider>
                </div>
            </section>
        );
    }
}
export default Hero;