import React, { Component } from 'react'

import axios from "axios"

import swal from 'sweetalert';


const dt = new Date()
const time = dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds();
const day = dt.getUTCDate();
const month = String(dt.getMonth() + 1).padStart(2, '0');
const year = dt.getFullYear();
const date = year + "-" + month + "-" + day;


class ContactForm extends Component {


    state = {
        name: '',
        email: '',
        subject: '',
        lastname: '',
        events: '',
        notes: '',
        error: {}
    }

    loading=false

    changeHandler = (e) => {
        const error = this.state.error;
        error[e.target.name] = ''

        this.setState({
            [e.target.name]: e.target.value,
            error
        })
    }

    subimtHandler = (e) => {
        e.preventDefault();

        const { name,
            email,
            subject,
            lastname,
            events,
            notes, error } = this.state;

        if (name === '') {
            error.name = "Please enter your name";
        }
        if (email === '') {
            error.email = "Please enter your email";
        }
        if (subject === '') {
            error.subject = "Please enter your subject";
        }
        if (lastname === '') {
            error.lastname = "Please enter your Lastname";
        }
        if (events === '') {
            error.events = "Select your event list";
        }
        if (notes === '') {
            error.notes = "Please enter your note";
        }

        if (error) {
            this.setState({
                error
            })

        }else{

        }
        if (error.name === '' && error.email === '' && error.email === '' && error.lastname === '' && error.subject === '' && error.events === '' && error.notes === '') {
            this.setState({
                name: '',
                email: '',
                subject: '',
                events: '',
                notes: '',
                error: {}
            })


        }else{

            
const addchat = async (email_,newMessage_) => {
    this.loading=true
    await axios.post("https://cromer.sisrevolution.com/api/news/contactus/", {
                   email:this.state.email,
                   name:this.state.name,
                   notes:this.state.notes,
                   subject:this.state.subject,
                   date:date,
                   time:time
  
                }).then(res => {
  
            if (res.status === 200) {
            
                this.loading=false
            //    dispatch(update_bookings({
            //     data: res.data
            // }))
            // setselected([])
            //     // setloading(!true)
  
                swal("", " Message Sent", "success");
  
  
            } else {
                this.loading=false
                // setloading(!true)
                swal("", "Error sending message", "error");
  
            }
  
        })
  
  }
  
  try{
  addchat()
  }catch(err){
    this.loading=false
  }

        }
    }

    render(){
        const { name,
            email,
            subject,
            lastname,
            notes,
            error } = this.state;

        return(
            <form onSubmit={this.subimtHandler} className="contact-form">
                <div className="row">
                    <div className="col-lg-6 col-12">
                        <div className="form-field">
                            <input required value={name} onChange={this.changeHandler} type="text" name="name" placeholder="First Name"/>
                            {/* <p>{error.name ? error.name : ''}</p> */}
                        </div>
                    </div>
                    <div className="col-lg-6 col-12">
                        <div className="form-field">
                            <input required value={lastname} onChange={this.changeHandler} type="text" name="lastname" placeholder="Lastname"/>
                            {/* <p>{error.lastname ? error.lastname : ''}</p> */}
                        </div>
                    </div>
                    <div className="col-lg-6 col-12">
                        <div className="form-field">
                            <input required onChange={this.changeHandler} value={email} type="email" name="email" placeholder="Email"/>
                            {/* <p>{error.email ? error.email : ''}</p> */}
                        </div>
                    </div>
                    <div className="col-lg-6 col-12">
                        <div className="form-field">
                            <input required onChange={this.changeHandler} value={subject} type="text" name="subject" placeholder="Subject"/>
                            {/* <p>{error.subject ? error.subject : ''}</p> */}
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-field">
                            <textarea required onChange={this.changeHandler} value={notes} name="notes" placeholder="Message"></textarea>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        {this.loading?
  <div className="form-submit">
  <button disabled  style={{color:"gray", backgroundColor:"lightgray"} }className="theme-btn"   >Loading...</button>
</div>
                        :
                        <div className="form-submit">
                            <button type="submit"  style={{color:"#ca9f40", backgroundColor:"black"} }className="theme-btn"   >Send Message</button>
                        </div>
    }
                    </div>
                </div>
            </form>
        )
    }

}
export default  ContactForm;