import React from 'react'
import SearchSection from './Rcomponents/select'
import Rooms from './Rcomponents/room'
import Description from './Rcomponents/description'
import './style.css'
import { useLocation } from "react-router-dom";
import Card from "../card"


const RoomSingleSection = (props) => {
   const location = useLocation();

  var data = location.state

  if(location.state !==undefined && (localStorage.getItem("room")===undefined || localStorage.getItem("room")===null)){
   localStorage.setItem("room",JSON.stringify(data))
  }else if(location.state ===undefined && (localStorage.getItem("room")!==undefined && localStorage.getItem("room")!==null)){
   data=JSON.parse(localStorage.getItem("room"))
  }

   
    return(
       <div className="room-single-page">
          <SearchSection data={data}/>
          <Rooms data={data}/>
          <Description data={data}/>
       </div>
    )
}

export default RoomSingleSection;