import React from 'react'
import {Link} from 'react-router-dom'
import ab1 from '../../images/about/about.png'
import './style.css'
import Videos from '../../components/videoSection'


const About = (props) => {
    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }
    return(
        <div className="wpo-about-area section-padding">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-5 col-md-12 col-sm-12">
                        {/* <div className="wpo-about-img">
                            <img src={ab1} alt=""/>
                        </div> */}

                    <Videos/>


                    </div>
                    <div className="col-lg-7 col-md-12 colsm-12">
                        <div className="wpo-about-text">
                            <div className="wpo-section-title">
                                {/* <span>About Us</span> */}
                                <h3 align="center">Welcome To</h3>
                                <h2 align="center" style={{color:"goldenrod"}}>Cromer Apartments</h2>
                            </div>
                            <p> Whether you are in Accra for business or leisure, we invite you to settle in and make Cromer Apartments your "home away from home". </p>
                            <div className="btns">
                                {/* <Link onClick={ClickHandler} to="/about" className="theme-btn-s2">More About Us</Link> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;