import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap';
import classnames from 'classnames';
import './style.css'
import {Link} from 'react-router-dom'
import rm1 from '../../images/room/img-1.jpg'
import rm2 from '../../images/room/img-2.jpg'
import rm3 from '../../images/room/img-3.jpg'
import rm4 from '../../images/room/img-4.jpg'
import rm5 from '../../images/room/img-5.jpg'
import rm6 from '../../images/room/img-6.jpg'
import rm7 from '../../images/garden.jpg'
import rm8 from '../../images/room/img-8.jpg'


const RoomSection = (props) => {

    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if(activeTab !== tab) setActiveTab(tab);
    }


    const Room = [

        {
            RoomImg:rm1,
            RoomHeading:"Studio Apartment",
            RoomCount:'Twin Room',
            Des:"",
            Price:"$90",
            Link:"/room-single_studio",
        },
        {
            RoomImg:rm2,
            RoomHeading:"Deluxe Apartment",
            RoomCount:'Twin Room',
            Des:"",
            Price:"$100",
            Link:"/room-single_deluxe",
        },
        {
            RoomImg:rm3,
            RoomHeading:"Superior Deluxe Apartment",
            RoomCount:'Twin Room',
            Des:"",
            Price:"$170",
            Link:"/room-single_superior",
        },
        ,
        {
            RoomImg:rm4,
            RoomHeading:"Executive Apartment",
            RoomCount:'',
            Des:"",
            Price:"$190",
            Link:"/room-single_executive",
        },

    ]

    const Room2 = [

       
        {
            RoomImg:rm5,
            RoomHeading:"Lobby",
            RoomCount:'Twin Room',
            Des:"About Lobby",
            Price:"$100",
            Link:"/room-single",
        },
        {
            RoomImg:rm6,
            RoomHeading:"Pool",
            RoomCount:'Twin Room',
            Des:"About pool",
            Price:"$100",
            Link:"/room-single",
        },
        {
            RoomImg:rm7,
            RoomHeading:"Garden",
            RoomCount:'Twin Room',
            Des:"About garden",
            Price:"$100",
            Link:"/room-single",
        },

    ]



    return(
        <section style={{marginTop:"-30px"}} className={`Room-area section-padding ${props.rClass}`}>
            <div className="Room-section">
                <div className="container">
                    <div className="col-12">
                        <div className="wpo-section-title">
                            {/* <span><b>Our Rooms</b></span> */}
                            <h2 style={{textAlign:"center", color:"#ca9f40"}}>Explore Cromer</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col col-xs-12 sortable-gallery">
                            <div className="gallery-filters">
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '1' })}
                                            onClick={() => { toggle('1'); }}
                                        >
                                          Our Apartments
                                        </NavLink>
                                    </NavItem>
                                    {/* <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '2' })}
                                            onClick={() => { toggle('2'); }}
                                        >
                                            Event Spaces
                                        </NavLink>
                                   
                                   </NavItem> */}
                                   
                                    
                                </Nav>
                            </div>
                            <div className="gallery-container">
                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId="1">
                                        {Room.map((room, rm) => (
                                                <div className="grid" key={rm}> 
                                                    <div className="room-item">
                                                        <img src={room.RoomImg} alt="" className="img img-responsive"/>
                                                        <div className="room-text-show">
                                                            <h2>{room.RoomHeading}</h2>
                                                        </div>
                                                        <div className="room-text-hide">
                                                            <h2>{room.RoomHeading}</h2>
                                                            {/* <span>{room.RoomCount}</span> */}
                                                            <p>{room.Des}</p>
<div align="center">
    { room.RoomHeading!=="Deluxe Apartment"?
<div><b>Price: {room.Price}  / Night</b><br/></div>
:
<div>
<b>Courtyard: $100  / Night</b><br/>
<b>Poolside: $115  / Night</b><br/>
</div>

}
                                                            <Link  to={room.Link} ><button className="theme-btn-s2" variant="primary" style={{background:"black", color:"#ca9f40", marginTop:"10px"}}> Explore </button> </Link>                                                         </div>
                                                            </div>
                                                    </div>
                                                </div>
                                            ))}
                                    </TabPane>
                                    <TabPane tabId="2">
                                        {Room2.map((room, rm) => (
                                            <div className="grid" key={rm}> 
                                                <div className="room-item">
                                                    <img src={room.RoomImg} alt="" className="img img-responsive"/>
                                                    <div className="room-text-show">
                                                        <h2>{room.RoomHeading}</h2>
                                                    </div>
                                                    <div className="room-text-hide">
                                                        <h2>{room.RoomHeading}</h2>
                                                        {/* <span>{room.RoomCount}</span> */}
                                                        <p>{room.Des}</p>
                                                        {/* <small>From: <span>{room.Price}</span> / Night</small> */}
                                                        {/* <Link className="theme-btn-s2" to={room.Link}>Check Availability</Link> */}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </TabPane>
                                   
                                    <TabPane tabId="4">
                                        {Room2.map((room, rm) => (
                                            <div className="grid" key={rm}> 
                                                <div className="room-item">
                                                    <img src={room.RoomImg} alt="" className="img img-responsive"/>
                                                    <div className="room-text-show">
                                                        <h2>{room.RoomHeading}</h2>
                                                    </div>
                                                    <div className="room-text-hide">
                                                        <h2>{room.RoomHeading}</h2>
                                                        <span>{room.RoomCount}</span>
                                                        <p>{room.Des}</p>
                                                        <small>From: <span>{room.Price}</span> / Night</small>
                                                        {/* <Link className="theme-btn-s2" to={room.Link}>Check Availability</Link> */}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </TabPane>
                                    <TabPane tabId="5">
                                        {Room.map((room, rm) => (
                                                <div className="grid" key={rm}> 
                                                    <div className="room-item">
                                                        <img src={room.RoomImg} alt="" className="img img-responsive"/>
                                                        <div className="room-text-show">
                                                            <h2>{room.RoomHeading}</h2>
                                                        </div>
                                                        <div className="room-text-hide">
                                                            <h2>{room.RoomHeading}</h2>
                                                            <span>{room.RoomCount}</span>
                                                            <p>{room.Des}</p>
                                                            <small>From: <span>{room.Price}</span> / Night</small>
                                                            {/* <Link className="theme-btn-s2" to={room.Link}>Check Availability</Link> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                    </TabPane>
                                </TabContent>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default RoomSection;